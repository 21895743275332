export default function (prefix) {
  return {
    prefix,
    CANCEL_BUTTON_REND: `${prefix}_CANCEL_BUTTON_REND`,
    EMAIL_ERR_REND: `${prefix}_EMAIL_ERR_REND`,
    ERR_REND: `${prefix}_ERR_REND`,
    FORM_CONTAINER_REND: `${prefix}_FORM_CONTAINER_REND`,
    FORM_TITLE_REND: `${prefix}_FORM_TITLE_REND`,
    FORM_NAME: `${prefix}_FORM_NAME`,
    FORM_EMAIL: `${prefix}_FORM_EMAIL`,
    FORM_PHONE: `${prefix}_FORM_PHONE`,
    FORM_MESSAGE: `${prefix}_FORM_MESSAGE`,
    FORM_SUBMIT_SUCCESS: `${prefix}_FORM_SUBMIT_SUCCESS`,
    FORM_SUBMIT_SUCCESS_MESSAGE: `${prefix}_FORM_SUBMIT_SUCCESS_MESSAGE`,
    FORM_SUBSCRIBE_SUCCESS_MESSAGE: `${prefix}_FORM_SUBSCRIBE_SUCCESS_MESSAGE`,
    FORM_REVEAL_BUTTON_REND: `${prefix}_FORM_REVEAL_BUTTON_REND`,
    FORM_COL1: `${prefix}_FORM_COL1`,
    FORM_COL2: `${prefix}_FORM_COL2`,
    FORM_CONTROLS: `${prefix}_FORM_CONTROLS`,
    FORM_CONTAINER_MOBILE: `${prefix}_FORM_CONTAINER_MOBILE`,
    FORM_CONTAINER_DESKTOP: `${prefix}_FORM_CONTAINER_DESKTOP`,
    FORM_CONTAINER_MOBILE_REND: `${prefix}_FORM_CONTAINER_MOBILE_REND`,
    FORM_CONTAINER_DESKTOP_REND: `${prefix}_FORM_CONTAINER_DESKTOP_REND`,
    FORM_RESPONSE_ERR_REND: `${prefix}_FORM_RESPONSE_ERR_REND`,
    FORM_EMAIL_OPT_IN: `${prefix}_FORM_EMAIL_OPT_IN`,
    SUBMIT_BUTTON_REND: `${prefix}_SUBMIT_BUTTON_REND`,
    ATTACHMENT_COUNT_REND: `${prefix}_ATTACHMENT_COUNT_REND`,
    FILE_INPUT_REND: `${prefix}_FILE_INPUT_REND`,
    FILE_NAME_REND: `${prefix}_FILE_NAME_REND`
  };
}
